
$hyoo_sync_watch_land $mol_book2_catalog
	yard $hyoo_sync_client
	param \head
	menu_title \Lands
	Menu_title $mol_view sub /
		<= Land_avatar $mol_avatar
			id <= land_id? \
		<= land_id? \
	menu_link_content* /
		<= Node_avatar* $mol_avatar
			id <= node_id* \
		<= Menu_link_title*
	spreads *
	Node* $mol_page
		title <= node_id* \
		head /
			<= Node_output_switch*
			<= Download* $mol_button_download
				blob <= download_blob* $mol_blob
				file_name <= download_name* \0_0.bin
			<= Spread_close
		body /
			<= Node_output_content*
	Node_output* $mol_deck
		Switch => Node_output_switch*
		Content => Node_output_content*
		current? <=> node_output? \0
		items /
			<= Node_units* $mol_grid
				title \Units
				records <= node_units* /
			<= Node_list* $mol_dump_list
				title \List
				values <= node_list* /
			<= Node_links* $mol_list
				title \Links
				rows <= node_links* /
					<= Node_link*0_0 $mol_link
						arg * land <= node_id*
						sub /
							<= node_id*
			<= Node_text* $mol_text_code
				title \Text
				text <= node_text* \
			<= Node_md* $mol_text
				title \MD
				text <= node_text* \
			<= Node_html* $mol_text_code
				title \HTML
				text <= node_html* \
			<= Node_json* $mol_dump_value
				title \JSON
				value <= node_json* null
			<= Node_blob* $mol_embed_native
				title \BLOB
				uri <= node_blob* null
