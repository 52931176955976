$hyoo_sync_watch_search $mol_book2_catalog
	title \Lands
	param \land
	query? \
	menu_head /
		<= Query $mol_search
			Clear null
			query? <=> search_query? \
			submit? <=> search_start? null
		<= Menu_tools
	body /
	menu_link_content* /
		<= Land_avatar* $mol_avatar
			id <= land_id* \
		<= Menu_link_title*
	Land* $hyoo_sync_watch_land
		land_id <= land_id*
		yard <= yard $hyoo_sync_client
		title <= land_id* \
		menu_tools /
			<= Spread_close
	level_options /
		\Law
		\Mod
		\Add
	Grab $mol_form
		body /
			<= Def_labeler $mol_form_field
				name \Common Level
				Content <= Def_level $mol_switch
					options <= level_options
					value? <=> def_level? \2
			<= Self_labeler $mol_form_field
				name \Your Level
				Content <= Self_level $mol_switch
					options <= level_options
					value? <=> self_level? \0
		buttons /
			<= Grab_submit $mol_button_major
				title \Grab New Land
				click? <=> grab_submit? null
