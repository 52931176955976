
$hyoo_sync_watch $mol_book2_catalog
	menu_title \CROWD Watch
	param \section
	Placeholder null
	plugins /
		<= Theme $mol_theme_auto
	menu_tools /
		<= Lights $mol_lights_toggle
		<= Sources $mol_link_source
			uri \https://github.com/hyoo-ru/sync.hyoo.ru
		<= Online $hyoo_sync_online
			yard <= yard $hyoo_sync_client
	spreads *
		search <= Search $hyoo_sync_watch_search
			yard <= yard
			menu_tools /
				<= Spread_close
		log <= Log $hyoo_sync_watch_log
			tools /
				<= Spread_close
		safe <= Safe $hyoo_meta_safe
			yard <= yard
